import React, { createContext, useContext, useEffect, useRef, useState } from 'react'
import { onAuthStateChanged } from 'firebase/auth'
import { auth, db } from './components/Firebase/firebase'
import { doc, collection, setDoc, getDoc, getDocs } from 'firebase/firestore'
import axios from 'axios'
import { CToast, CToastBody, CToastClose, CToaster } from '@coreui/react'

const Glg = createContext()

// eslint-disable-next-line react/prop-types
const GlgContext = ({ children }) => {
  const [user, setUser] = useState(null)
  const [userStat, setUserstat] = useState(null)
  const [stat, setStat] = useState(null)
  const [alert, addToast] = useState(0)
  const [cont, setCont] = useState(false)
  //After auth
  const getUserData = async (user) => {
    const userRef = doc(db, 'users', user.id)
    const docSnap = await getDoc(userRef)
    var dbData = docSnap.exists() ? docSnap.data() : null
    if (dbData) user = { ...user, ...dbData }
    setUser(user)
    setCont(true)
  }
  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        user.id = user.providerData[0]?.uid
        user.photoURL = user?.photoURL.replace(/s(\d+)-c/, 's256-c')
        //console.log(`User ${user.id} loaded`)
        getUserData(user)
      } else {
        setCont(true)
      }
    })
  }, [])

  async function getAllStat() {
    let today = new Date().getDate()
    let local_stats = localStorage.getItem('stats')
    if (local_stats) {
      var stats = JSON.parse(local_stats)
      if (stats.day === today) return setStat(stats.stats)
    }
    const statRef = doc(db, 'static', 'stats')
    const docSnap = await getDoc(statRef)
    if (docSnap.exists()) {
      let new_stats = docSnap.data()
      setStat(new_stats)
      let local_write = {
        day: today,
        stats: new_stats,
      }
      localStorage.setItem('stats', JSON.stringify(local_write))
    }
  }
  async function checkGetUserFB(user) {
    //console.log('Checking user...')
    if (cont)
      setAlert({
        open: true,
        message: `Привіт! ${user.displayName}`,
        type: 'success',
      })
    const userID = user.providerData[0]?.uid
    const userRef = doc(db, 'users', userID)
    const docSnap = await getDoc(userRef)
    var data = {
      id: userID,
      last_login: new Date(),
      email: user.email,
      fullname: user.displayName,
      link: `https://www.google.com/maps/contrib/${userID}`,
      photo: user.photoURL,
    }
    if (docSnap.exists()) {
      var old = docSnap.data()
      data = { ...old, ...data }
    } else {
      data.date = new Date()
      data.role = 'user'
      data.lang = 'uk'
    }
    if (Object.keys(data).length) setDoc(userRef, data, { merge: true })
    setUser({ ...user, ...data })
  }
  async function getUserStat(user) {
    const ustatRef = doc(db, 'stats', user?.id)
    const userSnap = await getDoc(ustatRef)
    if (userSnap.exists()) {
      //console.log(`Statistic loaded`)
      var us = userSnap.data()
      const histRef = await getDocs(collection(db, `stats/${user?.id}/history`))
      if (histRef) {
        //console.log(`History loaded`)
        us.history = {}
        histRef.forEach((doc) => {
          var [, day, month, year] = doc.id.toString().match(/(\d{2})(\d{2})(\d{4})/)
          us.history[new Date(`${year}/${month}/${day}`).getTime()] = doc.data()
        })
      } else {
        //console.log(`No stat for user id ${user?.id}`)
      }
      setUserstat(us)
    } else {
      axios
        .get(process.env.REACT_APP_FN_URL, {
          withCredentials: false,
          mode: 'no-cors',
          params: {
            fn: 'profile',
            data: user?.id,
          },
        })
        .then((response) => {
          if (response.data) {
            setUserstat(response.data)
            const userRef = doc(db, 'stats', user?.id)
            setDoc(userRef, response.data, { merge: true })
            const statRef = doc(db, 'static', 'stats')
            let staticS = {}
            staticS[user?.id] = response.data
            setDoc(statRef, staticS, { merge: true })
          } else {
            return null
          }
        })
    }
  }
  const toaster = useRef()
  const setAlert = (item) =>
    addToast(
      <CToast visible={item?.open || false} color={item?.type} className="align-items-center">
        <div className="d-flex">
          <CToastBody>{item?.message}</CToastBody>
          <CToastClose className="me-2 m-auto" />
        </div>
      </CToast>,
    )
  /* useEffect(() => {
    ReactGA.initialize(process.env.REACT_APP_ANALYTICS_TAG, { testMode: true })
    ReactGA.send({ hitType: 'pageview', page: window.location?.hash?.replace('#', '') })
  }, []) */
  return (
    <Glg.Provider
      value={{
        user,
        setUser,
        userStat,
        setUserstat,
        getUserStat,
        stat,
        getAllStat,
        cont,
        alert,
        setAlert,
        checkGetUserFB,
      }}
    >
      {children}
      <CToaster ref={toaster} push={alert} placement="bottom-end" />
    </Glg.Provider>
  )
}

export default GlgContext

export const GlgState = () => {
  return useContext(Glg)
}
