import 'react-app-polyfill/stable'
import 'core-js'
import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { Provider } from 'react-redux'
import store from './store'
import GlgContext from './GlgContext'

ReactDOM.render(
  <Provider store={store}>
    <GlgContext>
      <App />
    </GlgContext>
  </Provider>,
  document.getElementById('root'),
)

reportWebVitals()
